import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"

export default function Default({ data }) {

  return (
    <>
      <DefaultLayout data={data}>
      <div className="rs-club sec-spacer">
          <div className="container">
            <h3 className="title-bg">{data.wpPage.title}</h3>
            <div className="row justify-content-md-center">
              <div className="col-lg-6 col-md-12">
                <div className="rs-club-text">
                  <div dangerouslySetInnerHTML={{__html: data.wpPage.content}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </>
  )
}


export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      id
      title
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
    }

  }
`
